/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, constant} from "./core";


/**
 * Analytics App APIs
 */
export const analyticsAPIs = () => {
    // Analytics Overview
    analyticsOverview();

	// Visit Analysis
	visitAnalysis();
    
	// Geo Analysis
	geoAnalysis();

	// Activity Analysis
	activityAnalysis();

    // Blog Analysis
    blogAnalysis();

    // Online Users
    onlineUsers();

    // Recent Visits
    recentVisits();

    // Recent Activities
    recentActivities();
};


/**
 * Analytics Overview
 */
const analyticsOverview = () => {
    // Visit Analysis Overview
	const visitAnalysis = document.querySelector('#visit-analysis-overview');
	if (visitAnalysis) {
        let action = visitAnalysis.dataset.action;
        let doc    = document.querySelector('.doc');
        let chart;

		// Visit Analysis On Load
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				visit_analysis_overview: 'daily'
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels = eval(result.labels);
				let users  = eval(result.users);
				let visits = eval(result.visits);

                // Instantiate the Chart class
				chart = new Chart(visitAnalysis.getContext('2d'), {
					type:    'bar',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,		 				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
						datasets: [
							{
								label:           result.const_users,
								data:            users,			// [12, 14, 8, 22, 21, 7, 15]
								backgroundColor: 'rgba(242, 56, 56, 0.87)',
								borderColor:     'rgba(242, 56, 56, 0.5)'
							},
							{
								label:           result.const_visits,
								data:            visits,		// [84, 97, 63, 134, 119, 73, 108]
								backgroundColor: 'rgba(66, 131, 242, 0.87)',
								borderColor:     'rgba(66, 131, 242, 0.5)'
							}
						]
					},
					options: {
						scales: {
							x: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							y: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							}
						},
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    result.const_visits_users,
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});
    }

    
    // Activity Analysis Overview
	const activityAnalysis = document.querySelector('#activity-analysis-overview');
	if (activityAnalysis) {
        let action = activityAnalysis.dataset.action;
        let doc    = document.querySelector('.doc');
        let chart;

		// Activity Analysis On Load
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				activity_analysis_overview: 'daily'
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels = eval(result.labels);
				let users  = eval(result.users);
				let activities = eval(result.activities);

                // Instantiate the Chart class
				chart = new Chart(activityAnalysis.getContext('2d'), {
					type:    'line',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,		 				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
						datasets: [
							{
								label:           result.const_users,
								data:            users,			// [12, 14, 8, 22, 21, 7, 15]
								backgroundColor: 'rgba(242, 56, 56, 0.87)',
								borderColor:     'rgba(242, 56, 56, 0.5)'
							},
							{
								label:           result.const_activities,
								data:            activities,		// [84, 97, 63, 134, 119, 73, 108]
								backgroundColor: 'rgba(66, 131, 242, 0.87)',
								borderColor:     'rgba(66, 131, 242, 0.5)'
							}
						]
					},
					options: {
						scales: {
							x: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							y: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							}
						},
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    result.const_activities_users,
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});
    }


    // Geo Analysis Overview
	const geoAnalysis = document.querySelector('#geo-analysis-overview');
	if (geoAnalysis) {
        let action = geoAnalysis.dataset.action;
        let doc    = document.querySelector('.doc');
        let chart;

		// Geo Analysis On Load
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				geo_analysis_overview: true
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels = eval(result.labels);
				let visits = eval(result.visits);
		
                // Instantiate the Chart class
				chart = new Chart(geoAnalysis.getContext('2d'), {
					type:    'pie',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,						// ['Canada', 'America', 'Germany', 'Japan', 'England', 'Italy']
						datasets: [
							{
								label:           result.const_visits,
								data:            visits,		// [33, 24, 15, 12, 9, 1]
								backgroundColor: [
									'rgba(66, 131, 242, 0.87)',
									'rgba(242, 56, 56, 0.87)',
									'rgba(57, 182, 99, 0.87)',
									'rgba(61, 183, 39, 0.87)',
									'rgba(255, 192, 2, 0.87)',
									'rgba(252, 225, 22, 0.87)'
								],
								borderColor: () => (doc.classList.contains('doc-light')) ? '#FFFFFF' : '#242424',
								borderWidth: 2
							}
						]
					},
					options: {
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    result.const_visits_countries,
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});
    }
};


/**
 * Visit Analysis
 */
const visitAnalysis = () => {
	const visitAnalysisChart = document.querySelector('#visit-analysis-chart');
	if (visitAnalysisChart) {
        let action = visitAnalysisChart.dataset.action;
        let doc    = document.querySelector('.doc');
        let chart;

		// Visit Analysis On Load
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				visit_analysis: 'daily'
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels = eval(result.labels);
				let users  = eval(result.users);
				let visits = eval(result.visits);

                // Instantiate the Chart class
				chart = new Chart(visitAnalysisChart.getContext('2d'), {
					type:    'bar',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,		 				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
						datasets: [
							{
								label:           result.const_users,
								data:            users,			// [12, 14, 8, 22, 21, 7, 15]
								backgroundColor: 'rgba(242, 56, 56, 0.87)',
								borderColor:     'rgba(242, 56, 56, 0.5)'
							},
							{
								label:           result.const_visits,
								data:            visits,		// [84, 97, 63, 134, 119, 73, 108]
								backgroundColor: 'rgba(66, 131, 242, 0.87)',
								borderColor:     'rgba(66, 131, 242, 0.5)'
							}
						]
					},
					options: {
						scales: {
							x: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							y: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							}
						},
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    result.const_visits_users,
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});


		// Visit Analysis On Search
        const visitAnalysisSearch = document.querySelector('#visit-analysis-search');
        if (visitAnalysisSearch) {
            const searchSelect = visitAnalysisSearch.querySelector("select");
            const searchButton = visitAnalysisSearch.querySelector("button");

            // Handle button click
            searchButton.onclick = () => {
                // Query requirements
                const frame = searchSelect.value;

                // Query selector
                const buttonText = searchButton.innerHTML;

                // Prepare the button
                searchButton.setAttribute("disabled", true);
                searchButton.innerHTML = constant("Requesting...");

                // Destroy old chart
                chart.destroy();

                // Fetch more content
                fetch(action, {
                    method: 'put',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({visit_analysis: frame})
                })
                .then(response => response.json())
                .then(result => {
                    // Check result
                    if (result.status == 'success') {
                        let labels = eval(result.labels);
                        let users  = eval(result.users);
                        let visits = eval(result.visits);

                        // Instantiate the new Chart class
                        chart = new Chart(visitAnalysisChart.getContext('2d'), {
                            type:    'bar',     // line, bar, pie, doughnut, etc'
                            data:    {
                                labels:   labels,		 				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                                datasets: [
                                    {
                                        label:           result.const_users,
                                        data:            users,			// [12, 14, 8, 22, 21, 7, 15]
                                        backgroundColor: 'rgba(242, 56, 56, 0.87)',
                                        borderColor:     'rgba(242, 56, 56, 0.5)'
                                    },
                                    {
                                        label:           result.const_visits,
                                        data:            visits,		// [84, 97, 63, 134, 119, 73, 108]
                                        backgroundColor: 'rgba(66, 131, 242, 0.87)',
                                        borderColor:     'rgba(66, 131, 242, 0.5)'
                                    }
                                ]
                            },
                            options: {
                                scales: {
                                    x: {
                                        grid: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
                                        },
                                        ticks: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                        }
                                    },
                                    y: {
                                        grid: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
                                        },
                                        ticks: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                        }
                                    }
                                },
                                plugins: {
                                    legend: {
                                        labels: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text:    result.const_visits_users,
                                        color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
                                        font: {
                                            size:   16,
                                            weight: 300
                                        }
                                    }
                                }
                            }
                        });
                    }

                    // Reset the button
                    searchButton.removeAttribute("disabled");
                    searchButton.innerHTML = buttonText;
                    
                }).then(() => {
                    chart.clear();
                });

            };
        }
    }
    
};


/**
 * Geo Analysis
 */
const geoAnalysis = () => {
    const geoAnalysisTable = document.querySelector('#geo-analysis-table');
	const geoAnalysisChart = document.querySelector('#geo-analysis-chart');
	if (geoAnalysisTable && geoAnalysisChart) {
        let action = geoAnalysisChart.dataset.action;
        let doc    = document.querySelector('.doc');
        let chart;

		// Visit Analysis On Load
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				geo_analysis: 'today'
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
                /**
                 * Create the table
                 */
                geoAnalysisTable.innerHTML = result.table;
    
                // Geo Analysis Table (Load More)
                geoAnalysisTableMore();


                /**
                 * Create the chart
                 */
				let labels = eval(result.labels);
				let visits = eval(result.visits);
		
                // Instantiate the Chart class
				chart = new Chart(geoAnalysisChart.getContext('2d'), {
					type:    'pie',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,						// ['Canada', 'America', 'Germany', 'Japan', 'England', 'Italy']
						datasets: [
							{
								label:           result.const_visits,
								data:            visits,		// [33, 24, 15, 12, 9, 1]
								backgroundColor: [
									'rgba(66, 131, 242, 0.87)',
									'rgba(242, 56, 56, 0.87)',
									'rgba(57, 182, 99, 0.87)',
									'rgba(61, 183, 39, 0.87)',
									'rgba(255, 192, 2, 0.87)',
									'rgba(252, 225, 22, 0.87)'
								],
								borderColor: () => (doc.classList.contains('doc-light')) ? '#FFFFFF' : '#242424',
								borderWidth: 2
							}
						]
					},
					options: {
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    result.const_visits_countries,
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});


		// Visit Analysis On Search
        const geoAnalysisSearch = document.querySelector('#geo-analysis-search');
        if (geoAnalysisSearch) {
            const searchSelect = geoAnalysisSearch.querySelector("select");
            const searchButton = geoAnalysisSearch.querySelector("button");

            // Handle button click
            searchButton.onclick = () => {
                // Query requirements
                const frame = searchSelect.value;

                // Query selector
                const buttonText = searchButton.innerHTML;

                // Prepare the button
                searchButton.setAttribute("disabled", true);
                searchButton.innerHTML = constant("Requesting...");

                // Destroy old chart
                chart.destroy();

                // Fetch more content
                fetch(action, {
                    method: 'put',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({geo_analysis: frame})
                })
                .then(response => response.json())
                .then(result => {
                    // Check result
                    if (result.status == 'success') {
                        /**
                         * Create the table
                         */
                        geoAnalysisTable.innerHTML = result.table;

                        // Geo Analysis Table (Load More)
                        geoAnalysisTableMore();


                        /**
                         * Create the chart
                         */
                        let labels = eval(result.labels);
                        let visits = eval(result.visits);
                
                        // Instantiate the Chart class
                        chart = new Chart(geoAnalysisChart.getContext('2d'), {
                            type:    'pie',     // line, bar, pie, doughnut, etc'
                            data:    {
                                labels:   labels,						// ['Canada', 'America', 'Germany', 'Japan', 'England', 'Italy']
                                datasets: [
                                    {
                                        label:           result.const_visits,
                                        data:            visits,		// [33, 24, 15, 12, 9, 1]
                                        backgroundColor: [
                                            'rgba(66, 131, 242, 0.87)',
                                            'rgba(242, 56, 56, 0.87)',
                                            'rgba(57, 182, 99, 0.87)',
                                            'rgba(61, 183, 39, 0.87)',
                                            'rgba(255, 192, 2, 0.87)',
                                            'rgba(252, 225, 22, 0.87)'
                                        ],
                                        borderColor: () => (doc.classList.contains('doc-light')) ? '#FFFFFF' : '#242424',
                                        borderWidth: 2
                                    }
                                ]
                            },
                            options: {
                                plugins: {
                                    legend: {
                                        labels: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text:    result.const_visits_countries,
                                        color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
                                        font: {
                                            size:   16,
                                            weight: 300
                                        }
                                    }
                                }
                            }
                        });
                    }

                    // Reset the button
                    searchButton.removeAttribute("disabled");
                    searchButton.innerHTML = buttonText;
                    
                }).then(() => {
                    chart.clear();
                });

            };
        }
    }
    
};


/**
 * Geo Analysis Table (Load More)
 */
const geoAnalysisTableMore = () => {
    // Conutries table
    const geoAnalysisTable = document.querySelector('#geo-analysis-table');
    if (geoAnalysisTable) {
        const loadMore = document.querySelector('#load-more');
        // Handle button click
        loadMore.onclick = () => {
            // Query selector
            const buttonText = loadMore.innerHTML;
            const frame      = document.querySelector('#geo-analysis-search').querySelector("select").value;
            
            // Prepare the button
            loadMore.setAttribute("disabled", true);
            loadMore.innerHTML = constant("Requesting...");

            // Fetch more data
            fetch(loadMore.dataset.action, {
                method: 'put',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    geo_analysis_table: frame,
                    offset: loadMore.dataset.offset
                })
            })
            .then(response => response.json())
            .then(result => {
                // Check result
                if (result.status == 'success') {
                    const rows = eval(result.rows);

                    // Appent new rows to tbody
                    for (let row of rows) {
                        polaris.append('tr', geoAnalysisTable.querySelector("tbody"), row);
                    }

                    // Reset the button
                    loadMore.removeAttribute("disabled");
                    loadMore.innerHTML = buttonText;
                    
                    // Update offset
                    loadMore.dataset.offset = result.offset;

                    // Check remain
                    if (!result.remain) {
                        loadMore.classList.add('display-none');
                    }

                    // Initialize polaris
                    polaris.init();
                }
            });
        };
    }
};


/**
 * Activity Analysis
 */
const activityAnalysis = () => {
	const activityAnalysisChart = document.querySelector('#activity-analysis-chart');
	if (activityAnalysisChart) {
        let action = activityAnalysisChart.dataset.action;
        let doc    = document.querySelector('.doc');
        let chart;

		// Activity Analysis On Load
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				activity_analysis: 'daily'
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels = eval(result.labels);
				let users  = eval(result.users);
				let activities = eval(result.activities);

                // Instantiate the Chart class
				chart = new Chart(activityAnalysisChart.getContext('2d'), {
					type:    'line',     // line, bar, pie, doughnut, etc'
					data:    {
						labels:   labels,		 				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
						datasets: [
							{
								label:           result.const_users,
								data:            users,			// [12, 14, 8, 22, 21, 7, 15]
								backgroundColor: 'rgba(242, 56, 56, 0.87)',
								borderColor:     'rgba(242, 56, 56, 0.5)'
							},
							{
								label:           result.const_activities,
								data:            activities,		// [84, 97, 63, 134, 119, 73, 108]
								backgroundColor: 'rgba(66, 131, 242, 0.87)',
								borderColor:     'rgba(66, 131, 242, 0.5)'
							}
						]
					},
					options: {
						scales: {
							x: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							y: {
								grid: {
									color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
								},
								ticks: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							}
						},
						plugins: {
							legend: {
								labels: {
									color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
								}
							},
							title: {
								display: true,
								text:    result.const_activities_users,
								color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
								font: {
									size:   16,
									weight: 300
								}
							}
						}
					}
				});
			}
		});


		// Visit Analysis On Search
        const activityAnalysisSearch = document.querySelector('#activity-analysis-search');
        if (activityAnalysisSearch) {
            const searchSelect = activityAnalysisSearch.querySelector("select");
            const searchButton = activityAnalysisSearch.querySelector("button");

            // Handle button click
            searchButton.onclick = () => {
                // Query requirements
                const frame = searchSelect.value;

                // Query selector
                const buttonText = searchButton.innerHTML;

                // Prepare the button
                searchButton.setAttribute("disabled", true);
                searchButton.innerHTML = constant("Requesting...");

                // Destroy old chart
                chart.destroy();

                // Fetch more content
                fetch(action, {
                    method: 'put',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({activity_analysis: frame})
                })
                .then(response => response.json())
                .then(result => {
                    // Check result
                    if (result.status == 'success') {
                        let labels = eval(result.labels);
                        let users  = eval(result.users);
                        let activities = eval(result.activities);

                        // Instantiate the new Chart class
                        chart = new Chart(activityAnalysisChart.getContext('2d'), {
                            type:    'line',     // line, bar, pie, doughnut, etc'
                            data:    {
                                labels:   labels,		 				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                                datasets: [
                                    {
                                        label:           result.const_users,
                                        data:            users,			// [12, 14, 8, 22, 21, 7, 15]
                                        backgroundColor: 'rgba(242, 56, 56, 0.87)',
                                        borderColor:     'rgba(242, 56, 56, 0.5)'
                                    },
                                    {
                                        label:           result.const_activities,
                                        data:            activities,		// [84, 97, 63, 134, 119, 73, 108]
                                        backgroundColor: 'rgba(66, 131, 242, 0.87)',
                                        borderColor:     'rgba(66, 131, 242, 0.5)'
                                    }
                                ]
                            },
                            options: {
                                scales: {
                                    x: {
                                        grid: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
                                        },
                                        ticks: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                        }
                                    },
                                    y: {
                                        grid: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
                                        },
                                        ticks: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                        }
                                    }
                                },
                                plugins: {
                                    legend: {
                                        labels: {
                                            color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text:    result.const_activities_users,
                                        color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
                                        font: {
                                            size:   16,
                                            weight: 300
                                        }
                                    }
                                }
                            }
                        });
                    }

                    // Reset the button
                    searchButton.removeAttribute("disabled");
                    searchButton.innerHTML = buttonText;
                    
                }).then(() => {
                    chart.clear();
                });

            };
        }
    }
    
};


/**
 * Blog Analysis
 */
const blogAnalysis = () => {
    const form = document.querySelector('#blog-analysis');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form, false, null)
            .then((result) => {
                // Check result
				if (result.status) {
                    // Create table data
                    document.querySelector('#blog-analysis-table').innerHTML = result.data.table;
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Online Users
 */
const onlineUsers = () => {
    const onlineUsersTable = document.querySelector("#online-users");
    if (onlineUsersTable) {
        const loadMore = document.querySelector("#load-more");

        // Handle button click
        loadMore.onclick = () => {
            // Query selector
            const buttonText = loadMore.innerHTML;

            // Prepare the button
            loadMore.setAttribute("disabled", true);
            loadMore.innerHTML = constant("Requesting...");

            // Fetch more data
            fetch(loadMore.dataset.action, {
                method: 'put',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    online_users: loadMore.dataset.offset
                })
            })
            .then(response => response.json())
            .then(result => {
                // Check result
                if (result.status == 'success') {
                    const rows = eval(result.rows);

                    // Appent new rows to tbody
                    for (let row of rows) {
                        polaris.append('tr', onlineUsersTable.querySelector("tbody"), row);
                    }

                    // Reset the button
                    loadMore.removeAttribute("disabled");
                    loadMore.innerHTML = buttonText;
                    
                    // Update offset
                    loadMore.dataset.offset = result.offset;

                    // Check remain
                    if (!result.remain) {
                        loadMore.classList.add('display-none');
                    }

                    // Initialize polaris
				    polaris.init();
                }
            });
        };
    }
};


/**
 * Recent Visits
 */
const recentVisits = () => {
    const recentVisitsTable = document.querySelector("#recent-visits");
    if (recentVisitsTable) {
        const loadMore = document.querySelector("#load-more");

        // Handle button click
        loadMore.onclick = () => {
            // Query selector
            const buttonText = loadMore.innerHTML;

            // Prepare the button
            loadMore.setAttribute("disabled", true);
            loadMore.innerHTML = constant("Requesting...");

            // Fetch more visits
            fetch(loadMore.dataset.action, {
                method: 'put',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    recent_visits: loadMore.dataset.offset
                })
            })
            .then(response => response.json())
            .then(result => {
                // Check result
                if (result.status == 'success') {
                    const rows = eval(result.rows);

                    // Appent new rows to tbody
                    for (let row of rows) {
                        polaris.append('tr', recentVisitsTable.querySelector("tbody"), row);
                    }

                    // Reset the button
                    loadMore.removeAttribute("disabled");
                    loadMore.innerHTML = buttonText;
                    
                    // Update offset
                    loadMore.dataset.offset = result.offset;

                    // Check remain
                    if (!result.remain) {
                        loadMore.classList.add('display-none');
                    }

                    // Initialize polaris
				    polaris.init();
                }
            });
        };
    }
};


/**
 * Recent Actibities
 */
const recentActivities = () => {
    const recentActivitiesTable = document.querySelector("#recent-activities");
    if (recentActivitiesTable) {
        const loadMore = document.querySelector("#load-more");

        // Handle button click
        loadMore.onclick = () => {
            // Query selector
            const buttonText = loadMore.innerHTML;

            // Prepare the button
            loadMore.setAttribute("disabled", true);
            loadMore.innerHTML = constant("Requesting...");

            // Fetch more activities
            fetch(loadMore.dataset.action, {
                method: 'put',
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    recent_activities: loadMore.dataset.offset
                })
            })
            .then(response => response.json())
            .then(result => {
                // Check result
                if (result.status == 'success') {
                    const rows = eval(result.rows);

                    // Appent new rows to tbody
                    for (let row of rows) {
                        polaris.append('tr', recentActivitiesTable.querySelector("tbody"), row);
                    }

                    // Reset the button
                    loadMore.removeAttribute("disabled");
                    loadMore.innerHTML = buttonText;
                    
                    // Update offset
                    loadMore.dataset.offset = result.offset;

                    // Check remain
                    if (!result.remain) {
                        loadMore.classList.add('display-none');
                    }

                    // Initialize polaris
				    polaris.init();
                }
            });
        };
    }
};
